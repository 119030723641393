@font-face {
  font-family: "NexaBold";
  src: url("NexaBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NexaBook";
  src: url("NexaBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NexaLight";
  src: url("NexaLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
