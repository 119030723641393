@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700;800&display=swap");
body {
  font-family: "Archivo", sans-serif;
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 24px !important;
}
.ant-layout-sider,
.ant-menu {
  background: #12b6b0 !important;
  color: #fff !important;
}
.ant-layout-header {
  background: #ffff;
  padding: 0 10px;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #12b6b0 !important;
  background: #fff;
}
.ant-menu-item:hover {
  color: #fff !important;
}
.site-layout {
  margin-left: 10px;
}
.ant-input {
  width: 100% !important;
  height: 45px;
  font-family: "Archivo", sans-serif;
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 45px !important;
}
.ant-input::placeholder {
  color: gray !important;
  font-family: "Archivo", sans-serif;
}
.ant-menu-inline {
  border-right: 0px !important;
}
@media screen and (max-width: 470px) {
  .site-layout {
    margin-left: 0;
  }
}

/************Custom CSS************/
.title-page {
  color: #304156;
  margin: 0px;
  font: 700 22px "Archivo", sans-serif;
}
.white-section {
  background: #ffff;
  padding: 30px;
  margin-top: 30px;
}
.dashbtn {
  padding: 0 12px;
  height: 38px;
  line-height: 38px;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%) !important;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Archivo", sans-serif;
  letter-spacing: 1px;
  background: #12b6b0;
  color: white;
  border: none;
  cursor: pointer;
}
.heading-page {
  padding: 10px 20px;
  border-bottom: 1px solid #d6d6d6;
}
.heading-page .title h2 {
  font-family: "Archivo", sans-serif;
  margin: 0px;
}
.select-theme .theme-wrapper {
  width: 250px;
  height: 400px;
  margin: 0 auto;
}
.select-theme .theme-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.select-theme .thumbnails-wrapper {
  margin-top: 20px;
}
.analytics .analytics-thumbnails {
  width: 100%;
  height: 210px;
  border: 0;
  text-align: center;
  box-shadow: 0 8px 32px rgb(47 60 74 / 1%);
  background: #fff;
  text-align: center;
  position: relative;
  transition: all 0.6s ease;
}
.analytics .last-thumbnail {
  margin-bottom: 0 !important;
}
.analytics .secondthumnail {
  margin-top: 30px;
}
.align-center {
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.analytics .analytics-thumbnails .circle {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 129, 255, 0.17);
  padding: 0 !important;
  margin-bottom: 30px;
}

.analytics .analytics-thumbnails h6 {
  color: #828d99;
  font: 400 14px "Archivo", sans-serif;
  margin-top: 10px;
}
/*************contact-details************/
.contact-details .form-wrapper {
  padding: 40px 0;
}
.contact-details .note p {
  font: 400 16px "Archivo", sans-serif;
  line-height: 20px;
}
/************content-update******************/
.content-update .form-wrapper .icon-upload {
  font-size: 40px;
}
.content-update .form-wrapper h5 {
  margin-top: 40px;
  font: 400 14px "Archivo", sans-serif;
  color: #8f8b8b;
  letter-spacing: 0.6px;
}
.content-update .form-wrapper .form-thumbnail {
  margin-top: 30px;
}
.content-update .form-wrapper .form-thumbnail h6 {
  font: 400 12px "Archivo", sans-serif;
  margin-top: 10px;
  letter-spacing: 0.6px;
  color: #8f8b8b;
}
/************setup-instructions**************/
.setup-instructions h4 {
  font-size: 16px;
}
.setup-instructions .steps-rows {
  margin-top: 30px;
}
.setup-instructions .steps-rows .step-name {
  font-size: 16px;
  font-weight: 700;
}
.setup-instructions .steps-rows .step-text p {
  color: #282c34;
  margin: 0;
}
/************terms_and_conditions************/
.terms_and_conditions .title {
  margin-bottom: 20px;
  font-size: 20px;
  text-transform: capitalize;
}
.terms_and_conditions ul li,
.terms_and_conditions p,
.terms_and_conditions a {
  color: #000000;
}
.terms_and_conditions .play-stores h4 {
  font-size: 16px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.terms_and_conditions .play-stores:not(:first-child) {
  margin-top: 30px;
}

@media screen and (max-width: 470px) {
  .title-page {
    font-size: 20px;
  }
  .white-section {
    margin-top: 20px;
  }
  .analytics .secondthumnail {
    margin-top: 0px;
  }
  .analytics .analytics-thumbnails h6 {
    font-size: 13px;
  }
  .select-theme .theme-wrapper {
    width: 100%;
    height: 100%;
  }
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0px !important;
  }
  .contact-details .form-wrapper {
    padding: 20px 15px;
  }
  .heading-page {
    padding: 10px;
  }
  .contact-details .note p {
    font-size: 15px;
  }
  .ant-layout-sider {
    position: fixed !important;
    z-index: 100 !important;
    bottom: 0 !important;
    max-width: 100% !important;
    /* width: calc(100% - 60px) !important; */
    width: 100% !important;
    padding-right: 80px;
    left: 0 !important;
    right: 0 !important;
  }
  .ant-menu-inline {
    border-right: 0px !important;
  }
  .ant-menu {
    display: flex !important;
    justify-content: space-between !important;
  }
  .tawk-min-container button[type="button"] {
    bottom: 50px !important;
  }
  .ant-menu-inline .ant-menu-item {
    width: unset;
  }
  .analytics .analytics-thumbnails {
    height: 180px;
    margin-bottom: 15px;
  }
  .analytics .analytics-thumbnails .circle {
    margin-bottom: 15px;
  }
  .tawk-button-large {
    margin-top: -40px !important;
    z-index: 300 !important;
  }
  .ant-tooltip {
    display: none;
  }
  .ant-layout-content {
    padding: 20px 15px 60px 15px !important;
  }
  .setup-instructions .steps-rows {
    margin-top: 0;
  }
  .setup-instructions .steps-rows .step-name {
    margin-top: 15px;
  }
}
@media screen and (min-width: 471px) and (max-width: 560px) {
  .white-section {
    margin-top: 20px;
  }
  .select-theme .theme-wrapper {
    width: 100%;
    height: 100%;
  }
  .analytics .secondthumnail {
    margin-top: 0px;
  }
  .analytics .analytics-thumbnails {
    height: 180px;
    margin-bottom: 15px;
  }
  .analytics .analytics-thumbnails h6 {
    font-size: 16px;
  }
  .select-theme .theme-wrapper {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 561px) and (max-width: 768px) {
  .white-section {
    margin-top: 20px;
  }
  .analytics .secondthumnail {
    margin-top: 0px;
  }
  .analytics .analytics-thumbnails {
    height: 180px;
    margin-bottom: 15px;
  }
  .analytics .analytics-thumbnails h6 {
    font-size: 13px;
  }
  .select-theme .theme-wrapper {
    width: 100%;
    height: 100%;
  }
}

@import url("./font/final.css");

@font-face {
  font-family: "NexaBook";
  src: local("NexaBook"), url("./font/NexaBook.woff") format("woff");
}
.App {
  text-align: center;
  font-family: "NexaBook";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-block {
  margin: 40px auto;
  background-color: #cbf0f7;
  min-height: 400px;
  border-radius: 5px;
  font-family: "NexaBook";
}

.analytic-block {
  height: 120px;
  background-color: #cbf7df;
  margin: 20px auto;
  border-radius: 5px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "NexaBook";
}
.analytic-block p {
  margin: 0;
}

.formContainer {
  width: 60%;
  margin: 30px auto;
}

.logoPreview {
  width: 300px;
  height: 300px;
  background-color: #7587ac;
  margin: 15px auto;
}

input[type="text"] {
  border-radius: 3px;
  font-size: 1rem;
  margin-top: 8px;
}
input[type="color"] {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.uploadContainer {
  width: 80%;
  margin: 30px auto;
}

.colorButton {
  width: 200px;
  height: auto;
  padding: 10px;
  display: flex;
  background-color: cbf7df;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.colorButtonContainer {
  display: "flex";
  justify-content: "space-between";
  margin-top: "10px";
  background-color: rgb(243, 242, 242);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.uploadAndSaveButton {
  width: 100%;
  margin: 8px 0 10px 0px;
  background-color: #91c8f5;
  color: white;
  border-radius: 5px;
}
.uploadAndSaveButton,
.updateColor:hover {
  background-color: #6ebcfc;
  color: rgb(241, 241, 241);
}

.inputImage {
  width: 100%;
  background-color: rgb(243, 242, 242);
  margin: 10px 0 0 0;
  border: 1px solid rgb(233, 233, 233);
  padding: 10px;
}
.noBorder {
  border: solid 3px transparent;
}

.withBorder {
  border: solid 3px #12b6b0;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 8px 32px rgb(47 60 74 / 1%), 0 8px 16px rgb(47 60 74 / 2%);
}
